.studioPlayerContainer{
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
}

.studioPlayer{
    width: 100%;
    height: 100%;
}